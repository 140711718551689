<template>
  <div>
    <section>
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="12"
          >
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-crosshairs-gps'"
                />
              </sup>
              <span class="ml-2"><b>HQ ADDRESS</b></span>
              <p class="mb-1">al. Jerozolimskie 65/79</p>
              <p class="mb-1">LIM Center, 17th floor</p>
              <p class="mb-1">00-697 Warsaw</p>
            </div>
          </v-col>
          <v-col
            md="3"
            sm="12"
          >
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-crosshairs-gps'"
                />
              </sup>
              <span class="ml-2"><b>BRANCH ADRES</b></span>
              <p class="mb-1">ul. Heweliusza 5b/22</p>
              <p class="mb-1">60-281 Poznan</p>
              <p class="mb-1">+48 696 963 963</p>
            </div>
          </v-col>
          <v-col
            md="3"
            sm="12"
          >
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-crosshairs-gps'"
                />
              </sup>
              <span class="ml-2"><b>BRANCH ADRES</b></span>
              <p class="mb-1">ul. Kruszyńska 30b</p>
              <p class="mb-1">01-365 Warsaw</p>
              <p class="mb-1">+48 782 519 807</p>
            </div>
          </v-col>
          <v-col
            md="3"
            sm="12"
          >
            <div class="mb-2">
              <span><b>XTERRA<sup>®</sup> CONSULTING</b></span>
              <p class="mb-1">ENERGY INDUSTRY</p>
              <p class="mb-1">REAL ESTATE MARKET</p>
              <p class="mb-1">contact@xterra.group</p>
            </div>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="10">
            <div class="mb-2">
              <p
                class="mb-1"
                style="font-size:0.75rem;"
              >
                Polgrid SA zarejestrowana w Rejestrze Przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS 0000580980 przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XII Wydział Gospodarczy. Wysokość kapitału zakładowego i kapitału wpłaconego: 1 000 000 zł
              </p>
            </div>
          </v-col>
        </v-row> -->
      </v-container>
    </section>
    <section class="py-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.8657141594495!2d21.002055615796593!3d52.227657479759884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecc8d5fcdddf5%3A0xbe0b76ba7c9b4048!2sal.%20Jerozolimskie%2065%2F79%2C%2000-698%20Warszawa!5e0!3m2!1sen!2spl!4v1668327166250!5m2!1sen!2spl"
        width="100%"
        height="600"
        frameborder="0"
        style="border:0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </section>

    <!-- <section class="grey lighten-3">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Your Name*"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Your Email*"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Subject"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Your Message"
              />
            </v-col>
            <v-col text-center>
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                class="px-5 text-capitalize"
                color="secondary"
                large
              >
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section> -->
  </div>
</template>

<script>
  export default {
  }
</script>
